import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { del, get, post } from '../../utils/api-services';
import themeColors from '../../assets/colors';
import ViewTitle from '../../common/view-title';
import Crud from '../../common/crud';

dayjs.extend(isoWeek);

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
        '& .row-error': {
            backgroundColor: themeColors.visible['false']
        },
    }
}));

const EmployeePayDaily = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        data: [],
        permissions: {}
    });


    useEffect(() => {
        window.document.title = 'Employee pay (daily)';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employeePayDaily/search', { date: state.date });
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response?.data ?? [] }));
        } else {
            console.log('[EmployeePayDaily] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }));

    const store = async () => {
        const { date } = state;
        const cond = window.confirm(`Are you sure you want to generate payments with date: ${date}?`);
        if (cond) {
            showLoader(true);
            const response = await post('/employeePayDaily/store', { date });
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));

                if (response.message) showToast(response.message);

                if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
            } else {
                console.log('[EmployeePayDaily] search error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const search = async () => {
        const { date } = state;
        showLoader(true);
        const response = await get('/employeePayDaily/search', { date });
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no checks with these filters', 'warning');
        } else {
            console.log('[EmployeePayDaily] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async () => {
        const { date } = state;
        const cond = window.confirm(`Are you sure you want to delete all payments with date: ${date}?\n\nThis action cannot be reversed.`);

        if (cond) {
            showLoader(true);
            const response = await del('/employeePayDaily/multipleDelete', { date });
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));

                showToast(response.message);
            } else {
                console.log('[EmployeePayDaily] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const renderTotal = params => `$ ${params.value}`;

    const columns = [
        { field: 'date', headerName: 'Date', flex: 0.5, minWidth: 120 },
        { field: 'name', headerName: 'Employee', flex: 1, minWidth: 240 },
        { field: 'total', headerName: 'Total', flex: 0.7, minWidth: 160, valueGetter: renderTotal },
    ]

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employee payment (daily)' />
            <Grid container>
                <Grid item xs={12} sm={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: () => { }, store }}
                destroy={destroy}
            />

            <div className={classes.buttonsContainer}>
                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.data}
                    getRowId={row => row.id}
                    getRowClassName={({ row }) => (row.error) ? 'row-error' : ''}
                    disableSelectionOnClick
                />
            </div>

        </div>
    );
}

export default EmployeePayDaily;