import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams, useHistory, Link } from "react-router-dom";
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, InputAdornment, TextField, Button, Icon, IconButton, Divider, Collapse } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import NotFound from "../../common/not-found";

import AuthContext from "../../context";
import { get, post, put, del } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import themeColors from "../../assets/colors";

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		marginBottom: 20,
	},
	tableContainer: {
		width: "100%",
		"& .total": {
			backgroundColor: "#E0F8E0",
		},
	},
	wrapText: {
		"&>.MuiAlert-message": {
			whiteSpace: "pre-wrap",
		},
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	bottom: {
		display: "flex",
		alignSelf: "flex-end",
	},
	store: {
		marginLeft: 20,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	update: {
		marginLeft: 20,
		backgroundColor: "#ff7800",
		borderColor: "#ff7800",
		"&:hover": {
			backgroundColor: "#ff7800",
			borderColor: "#ff7800",
			boxShadow: "none",
		},
	},
	delete: {
		width: "100%",
		backgroundColor: "#dc3545",
		borderColor: "#dc3545",
		"&:hover": {
			backgroundColor: "#dc3545",
			borderColor: "#dc3545",
			boxShadow: "none",
		},
	},
	calculate: {
		marginLeft: 20,
		backgroundColor: "#6E6E6E",
		borderColor: "#6E6E6E",
		"&:hover": {
			backgroundColor: "#6E6E6E",
			borderColor: "#6E6E6E",
			boxShadow: "none",
		},
	},
	generate: {
		marginLeft: 20,
		backgroundColor: "#198754",
		borderColor: "#198754",
		"&:hover": {
			backgroundColor: "#198754",
			borderColor: "#198754",
			boxShadow: "none",
		},
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	rowText: {
		display: "inherit",
		flexDirection: "inherit",
		alignItems: "inherit",
	},
	state: {
		fontSize: "1rem",
		marginLeft: 5,
		marginRight: 10,
		color: themeColors.gray[600],
	},
	divider: {
		marginBottom: 24,
	},
	highlight: {
		color: "#0d6efd",
	},
	controls: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	requiredValue: {
		fontWeight: "600",
		color: "rgba(255,0,0,1)",
	},
}));

const defaultProduct = {id: 0, name: "Choose a product"};
const defaultItem = {id: 0, name: "Choose an item"};
const defaultTicket = {
		state: "Not paid",
		ff_product: defaultProduct,
		ff_item: defaultItem,
		code: "",
		date: "",
		supplier: {id: 0, name: "Choose a supplier"},
		land: {id: 0, name: "Choose a land"},
		payment_number: "",
		quantity: "",
		price: "",
		total: "",
		note: ""
	}

export default function BillsCuttingTicketsCreateUpdate({ permissions, isPayment = false }) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const { id } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const [showDetail, setShowDetail] = useState(true);
	const [state, setState] = useState({
		loading: true,
		suppliers: [],
		lands: [],
		items: [],
		ticket: defaultTicket,
		products: [],
		permissions: {},
		filteredProducts: []
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		let wName = typeof id !== "undefined" ? "Update" : "Create"
		window.document.title = isPayment ? "Payment Ticket" : `${wName} Bills and Cutting Ticket`;
		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);
		var urlData = typeof id === "undefined" ? `/farmForest/billsAndCuttingTickets/options` : `/farmForest/billsAndCuttingTickets/${id}`
		const response = await get(urlData);
		if (response.status !== 200) {
			console.log("[BillsAndCuttingTicketDetails] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
			setState((prev) => ({ ...prev, loading: false }));
			showLoader(false);
			return;
		}

		if(typeof id !== "undefined"){
			const sede = JSON.parse(localStorage.getItem("@sede"));
			if (response?.data?.status === "inactive" || response?.data?.idsede !== sede.id) {
				showToast("This ticket is not available");
				history.replace("/farmForest/billsAndCuttingTickets/list");
			}
		}

		let tempFilterProducts = [];
		if(!response.data){
			tempFilterProducts = response.products ?? [];
		}else{
			let products = response.products.filter(p => p.idff_item === response.data?.ff_item?.id);
			tempFilterProducts = products;
		}

		setState((prev) => ({
			...prev,
			items: response?.items ?? [],
			products: response?.products ?? [],
			suppliers: response?.suppliers ?? [],
			lands: response?.lands ?? [],
			ticket: {...prev.ticket, ...response?.data ?? {}},
			filteredProducts: tempFilterProducts,
			loading: false,
		}));

		showLoader(false);
	};

	const handlePaymentChange = ({ target }) => {
		let status = ""
		if (target.value.length > 0 && target.value !== "") {
			status = "Paid"
		} else {
			status = "Not paid";
		}
		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value, state: status} }))
	}

	const handleChange = ({ target }) => {
		setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value  } }))
	};

	const handleNumber = ({ target }) => {
		if(/^\d*\.?\d{0,2}$/.test(target.value) || target.value === ""){
			setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value, total: calculateTotal(target)  } }))
		}
	}

	const handleNumberFour = ({ target }) => {
		if(/^\d*\.?\d{0,4}$/.test(target.value) || target.value === ""){
			setState((prev) => ({ ...prev, ticket: { ...prev.ticket, [target.name]: target.value, total: calculateTotal(target)  } }))
		}
	}
	
	const calculateTotal = (target) => {
		if(target.name === "quantity"){
			return Math.round(((state.ticket.price * target.value) + Number.EPSILON) * 100) / 100
		}else{
			return Math.round(((state.ticket.quantity * target.value) + Number.EPSILON) * 100) / 100
		}
	}

	const handleAutocomplete = (key, value) => {
		if(key === "ff_item"){
			if(value?.id === 0 || value === null){
				setState((prev) => ({ ...prev, filteredProducts: state.products, ticket: {...prev.ticket, ff_product: defaultProduct, ff_item: defaultItem}}))
				return
			}else{
				let products = state.products.filter(p => p.idff_item === value?.id);
				setState((prev) => ({ ...prev, filteredProducts: [defaultProduct].concat(products), ticket: {...prev.ticket, ff_product: defaultProduct}}))
			}
		}
		if(key === "ff_product"){
			if(value?.id === 0 || value === null){
				setState((prev) => ({ ...prev, filteredProducts: state.products, ticket: {...prev.ticket, ff_product: defaultProduct, ff_item: defaultItem}}))
				return
			}else{
				let product = state.products.find(p => p.id === value?.id);
				let item = state.items.find(i => i.id === product.idff_item);
				if(product.idff_item !== state.ticket.ff_item.id){
					let products = state.products.filter(p => p.idff_item === item.id);
					setState((prev) => ({ ...prev, filteredProducts: [defaultProduct].concat(products), ticket: {...prev.ticket, ff_item: item}}))
				}
			}
		}
		setState((prev) => ({ ...prev, ticket: {...prev.ticket, [key]: value}}))
	};

	const isNumeric = (str) => {
		if (typeof str == "number") return true; // if the value is alredy a number return true
		if (typeof str != "string") return false; // only validate strings
		// use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		// ...and ensure strings of whitespace fail
		return !isNaN(str) && !isNaN(parseFloat(str));
	};

	const validateForm = (option) => {

		switch (option) {
			
			case "store":
				return validateFiledsRequiredFromForm()

			case "delete":
				if (state.ticket.idff_ticket_bill == 0) {
					showToast("Please try later", "error");
					return false;
				}
				const dCond = window.confirm("Are you sure you want to delete this ticket?");

				return Boolean(state.ticket.idff_ticket_bill && dCond);
			default:
				break;
		}
	};

	const store = async () => {
		showLoader(true);
		const cond = validateForm("store");
		
		if (!cond){
			showLoader(false);
			return;
		}

		if (typeof id === "undefined") {
			const response = await post("/farmForest/billsAndCuttingTickets/store", state.ticket);
			if (response.status === 200) {
				setState((prev) => ({ ...prev, ticket: defaultTicket, loading: false }));
				window.scrollTo(0, 0)
				showToast(response.message);
			} else {
				console.log("[BillsAndCuttingCreateTicket] store error", response);
				helpers.failRequest(response, showToast, signOut);
			}

			showLoader(false);
			return;
		}else{
			const response = await put(`/farmForest/billsAndCuttingTickets/${state.ticket.idff_ticket_bill}/update`, state.ticket);
			if (response.status === 200) {
				showToast(response.message);
				setState((prev) => ({ ...prev, ticket: { ...prev.ticket, ...response.data}}))
			} else {
				console.log("[BillsAndCuttingUpdateTicket] update error", response);
				helpers.failRequest(response, showToast, signOut);
			}
		}

		showLoader(false);
	};

	const destroy = async () => {
		const cond = validateForm("delete");

		if (cond) {
			showLoader(true);
			const response = await del(`/farmForest/billsAndCuttingTickets/${state.ticket.idff_ticket_bill}`);
			if (response.status === 200) {
				history.replace("/farmForest/billsAndCuttingTickets");
				showToast(response.message);
			} else {
				console.log("[BillsAndCuttingTicketsDestroy] destroy error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const validateFiledsRequiredFromForm = () => {
		const currentState = state.ticket;
		let issues = 0;
		let msg = "";

		if (!currentState.code || currentState.code === "") {
			issues += 1;
			msg += "Code can't be empty\n";
		}

		if (!currentState.date) {
			issues += 1;
			msg += "Date can't be empty\n";
		}

		if (!currentState.supplier || currentState.supplier["id"] === 0) {
			issues += 1;
			msg += "Supplier can't be empty\n";
		}

		if (!currentState.land || currentState.land["id"] === 0) {
			issues += 1;
			msg += "Land can't be empty\n";
		}

		if (!currentState.ff_item || currentState.ff_item["id"] === 0) {
			issues += 1;
			msg += "Item can't be empty\n";
		}

		if (!currentState.ff_product || currentState.ff_product["id"] === 0) {
			issues += 1;
			msg += "Product can't be empty\n";
		}

		if (!currentState.quantity || currentState.quantity === "") {
			issues += 1;
			msg += "Quantity can't be empty\n";
		}else{
			if (!isNumeric(currentState.quantity)) {
				issues += 1;
				msg += "Quantity doesn't have a valid value\n";
			}
		}

		if (!currentState.price || currentState.price === "") {
			issues += 1;
			msg += "Price can't be empty\n";
		}else{
			if (!isNumeric(currentState.price)) {
				issues += 1;
				msg += "Price doesn't have a valid value\n";
			}
		}

		if (issues > 0) showToast(msg, "error", 4000, classes.wrapText);

		return issues > 0 ? false : true;
	};

	if (state.loading) {
		return <Loader fullScreen />;
	}
	if (!state.permissions.read_attr) {
		return <NotFound />;
	}

	return (
		<div className={classes.root}>
			<Divider />
			<div className={classes.row}>
				<div className={classes.rowText}>
					<h3>{"State"}</h3>
					<label className={classes.state}>{`${state.ticket.state} ${state.ticket.payment_number ? state.ticket.payment_number : "" }`}</label>
				</div>
				{state.permissions?.delete_attr && typeof id !== "undefined" && (
					<Grid item xs={12} lg={2} className={classes.buttonContainer}>
						<Button variant="contained" className={classes.delete} onClick={destroy}>
							{"Delete"}
						</Button>
					</Grid>
				)}
			</div>
			<Divider className={classes.divider} />
			<Grid container>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"ID"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput disabled autoComplete="off" type="text" placeholder="ID" name="id" value={typeof id !== "undefined" ? id : "New"} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"No. Ticket"}<span className={classes.requiredValue}>*</span>{" "}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput disabled={typeof id !== "undefined" ? true : false} autoComplete="off" type="text" placeholder="No. ticket" name="code" value={state.ticket.code ?? ""} onChange={handleChange}/>
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={4}>
					<FormGroup>
						<FormLabel>{"Date"} <span className={classes.requiredValue}>*</span>{" "}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="date" placeholder="Date" name="date" value={state.ticket.date} onChange={handleChange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={12} lg={typeof id !== "undefined" ? 5 : 6}>
					<FormGroup>
						<FormLabel>{"Supplier"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
						<Autocomplete
							options={state.suppliers}
							value={state.ticket?.supplier}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleAutocomplete("supplier", value)}
							renderInput={(params) => <TextField {...params} placeholder="Supplier" />}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={12} lg={typeof id !== "undefined" ? 5 : 6}>
					<FormGroup>
						<FormLabel>{"Land"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
						<Autocomplete
							options={state.lands}
							value={state.ticket?.land}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleAutocomplete("land", value)}
							renderInput={(params) => <TextField {...params} placeholder="Lands" />}
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<Divider />

			<div className={classes.row}>
				<h2>{"Payment"}</h2>
				<IconButton onClick={() => setShowDetail(!showDetail)}>
					<Icon>{showDetail ? "expand_less" : "expand_more"}</Icon>
				</IconButton>
			</div>
			<Collapse in={showDetail}>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Payment number"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Payment Number" name="payment_number" value={state.ticket?.payment_number ?? ""} onChange={handlePaymentChange} />
							</FormControl>
						</FormGroup>
					</Grid>
				</Grid>
			</Collapse>

			<Divider />

			<div className={classes.row}>
				<h2>{"Detail"}</h2>
				<IconButton onClick={() => setShowDetail(!showDetail)}>
					<Icon>{showDetail ? "expand_less" : "expand_more"}</Icon>
				</IconButton>
			</div>
			<Collapse in={showDetail}>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Item"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<Autocomplete
								options={state.items}
								value={state.ticket.ff_item}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => handleAutocomplete("ff_item", value)}
								renderInput={(params) => <TextField {...params} placeholder="Item" />}
							/>
						</FormGroup>
					</Grid>
					<Grid item xs={12} lg={6}>
						<FormGroup>
							<FormLabel>{"Product"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<Autocomplete
								options={state.filteredProducts}
								value={state.ticket.ff_product}
								getOptionLabel={(option) => option?.name ?? ""}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(event, value) => {
									handleAutocomplete("ff_product", value);
								}}
								renderInput={(params) => <TextField {...params} placeholder="Product" />}
							/>
						</FormGroup>
					</Grid>

				</Grid>

				<Grid container>
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Quantity"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="number" placeholder="Quantity" name="quantity" value={state.ticket?.quantity} onChange={handleNumberFour} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Price"} <span className={classes.requiredValue}>*</span>{" "} </FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>} type="number" placeholder="Price" name="price" value={state.ticket?.price} onChange={handleNumberFour} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={12} lg={4}>
						<FormGroup>
							<FormLabel>{"Total"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput
									disabled
									inputProps={{ className: classes.highlight }}
									startAdornment={<InputAdornment position="start">{"$"}</InputAdornment>}
									type="number"
									placeholder="Total"
									name="total"
									value={state.ticket?.total}
								/>
							</FormControl>
						</FormGroup>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={12} lg={12}>
						<FormGroup>
							<FormLabel>{"Notes"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Note" name="note" value={state.ticket?.note ?? ""} onChange={handleChange} />
							</FormControl>
						</FormGroup>
					</Grid>
				</Grid>
			</Collapse>

			<div className={classes.bottom}>
				{state.permissions?.update_attr && (
					<div className={classes.buttonContainer}>
						<Button variant="contained" className={typeof id !== "undefined" ? classes.update : classes.store} onClick={store}>
							{typeof id !== "undefined" ? "Update" : "Store"}
						</Button>
					</div>
				)}
				</div>
			</div>
			
	);
}
