import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Divider,
} from "@material-ui/core";
import DateRange from "../../common/date-range";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { get, post } from "../../utils/api-services";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import ViewTitle from "../../common/view-title";
import XLSX from "xlsx";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
    width: "100%",
  },
  clear: {
    backgroundColor: "#A4A4A4",
    borderColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
      borderColor: "#A4A4A4",
      boxShadow: "none",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: 10,
  },
  generateExcelButton: {
    backgroundColor: "#6c757d",
    borderColor: "#6c757d",
    marginLeft: 50,
    "&:hover": {
      backgroundColor: "#5a6268",
      borderColor: "#5a6268",
      boxShadow: "none",
    },
  },

  divider: {
    marginBottom: 10,
  },
  filterButton: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
}));

const ControlReport = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    data: [],
    filter: {
      weeks: [],
      startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      dateRange: `${dayjs()
        .subtract(1, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
    },
    rangeModal: {
      open: false,
      range: [
        {
          startDate: new Date(dayjs().subtract(1, "day").toDate()),
          endDate: new Date(),
          key: "main",
        },
      ],
    },
    reportFilters: [],
  });

  useEffect(() => {
    window.document.title = "Cashier Report";
    validatePermissions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/cashierControlReport/index", {
      idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
      sudo: JSON.parse(localStorage.getItem("@auth_user")).special_permission,
    });
    setState((prev) => ({ ...prev, data: response.data }));
    showLoader(false);
  };

  const handleChange = ({ target }) =>
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [target.name]: target.value.trim() },
    }));

  const handleModal = () => {
    setState((prev) => ({
      ...prev,
      rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open },
    }));
  };

  const handleDateChange = (item) => {
    const { startDate, endDate } = item.main;

    const formattedStartDate = dayjs(startDate.toISOString()).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = dayjs(endDate.toISOString()).format("YYYY-MM-DD");

    const getWeekNumber = (date) => {
      const startOfYear = dayjs(date).startOf("year");
      const daysPassed = dayjs(date).diff(startOfYear, "day");
      const startOfWeekOffset = startOfYear.day();
      const weekNumber = Math.ceil((daysPassed + startOfWeekOffset) / 7);

      return isNaN(weekNumber) ? "" : weekNumber.toString();
    };

    const startWeek = parseInt(getWeekNumber(startDate), 10);
    const endWeek = parseInt(getWeekNumber(endDate), 10);

    const weeks = [];
    for (let week = startWeek; week <= endWeek; week++) {
      weeks.push(week);
    }

    setState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        dateRange: `${dayjs(startDate).format("MM/DD/YYYY")} - ${dayjs(
          endDate
        ).format("MM/DD/YYYY")}`,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        weeks: weeks,
      },
      rangeModal: { ...prev.rangeModal, range: [item.main] },
      handleDateRange: true,
    }));
  };

  const handleRowSelection = (selection) => {
    const selectedRows = state.data.filter((row) => selection.includes(row.id));
    setState((prev) => ({
      ...prev,
      reportFilters: selectedRows,
    }));
  };

  const generateExcel = async () => {
    try {
      if (
        !state.reportFilters.length ||
        !state.reportFilters[0]?.idsupervisor
      ) {
        throw new Error("No report filters or idsupervisor found.");
      }

      const queryData = {
        weeks: state.reportFilters.map((filter) => filter.week),
        idsupervisor: state.reportFilters[0].idsupervisor,
        idsede: state.reportFilters[0].idsede,
      };

      if(state.handleDateRange){
        queryData.startDate = state.filter.startDate
        queryData.endDate = state.filter.endDate
      }

      const encodedQuery = encodeURIComponent(JSON.stringify(queryData));
      const response = await get("/cashierControlReportGenerate/search", {
        reports: encodedQuery,
      });

      console.log(response);

      if (response.status === 200 && response.data.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(response.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `report_${Date.now()}.xlsx`);
      }
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const clear = async () => {
    setState((prev) => ({
      ...prev,
      filter: {
        weeks: [],
        startDate: "",
        endDate: "",
        dateRange: "",
        name: "",
      },
    }));
  };

  const filter = async () => {
    const response = await get("/cashierControlReport/search", {
      ...state.filter,
      weeks: state.filter.weeks.join(","),
    });
    console.log(state.filter);
    setState((prev) => ({ ...prev, data: response.data }));
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.8,
      minWidth: 75,
    },
    {
      field: "week",
      headerName: "Week",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      flex: 1,
      minWidth: 75,
      renderCell: (params) => {
        const capitalizeWords = (str) => {
          return str
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        };

        return capitalizeWords(params.value || "");
      },
    },
  ];

  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Cashier Report" />
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormGroup onClick={handleModal}>
            <FormLabel>{"Date range"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                readOnly
                autoComplete="off"
                type="text"
                placeholder="Date range"
                name="range"
                value={state.filter.dateRange}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Week"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="text"
                placeholder={"Week"}
                name={"week"}
                value={state.filter.weeks.join(",")}
                onChange={handleChange}
                disabled
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Supervisor"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="text"
                placeholder={"Supervisor"}
                name={"name"}
                value={state.filter.name}
                onChange={handleChange}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button variant="contained" className={classes.clear} onClick={clear}>
          {"Clear"}
        </Button>
        <Button
          variant="contained"
          className={classes.filterButton}
          onClick={filter}
        >
          {"Search"}
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={classes.generateExcelButton}
          onClick={generateExcel}
          disabled={state.reportFilters[0] ? false : true}
        >
          {"Download Report"}
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <DataGrid
          className={classes.controlTable}
          rows={state.data}
          columns={columns}
          pageSize={20}
          density="compact"
          autoHeight
          getRowId={(row) => row.id}
          checkboxSelection
          onSelectionModelChange={handleRowSelection}
        />
      </div>
      <DateRange
        open={state.rangeModal.open}
        range={state.rangeModal.range}
        onClose={handleModal}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default ControlReport;
