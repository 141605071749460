import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, TextField, Button, FilledInput, FormControl, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';
import XLSX from 'xlsx';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get, post } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Avoided from '../components/avoided';
dayjs.extend(utc);
dayjs.extend(timezone);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const ReportEmployees = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        loading: true,
        CC1: [],
        CC2: [],
        CC3: [],
        filter: {
            cc1: { id: 0, name: 'All CC1' },
            cc2: { id: 0, name: 'All CC2' },
            cc3: { id: 0, name: 'All CC3' }
        },
        data: [],
        fileData: [],
        rejected: []
    });

    useEffect(() => {
        makeRequest();
    }, []);

    useEffect(() => {
        if (state.rejected?.length > 0) {
            setOpen(true);
        }
    }, [state.rejected]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexEmployees', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                CC1: response?.cc1 ?? [],
                CC2: response?.cc2 ?? [],
                CC3: response?.cc3 ?? []
            }));
        } else {
            console.log('[ReportEmployees] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchEmployees', state.filter);
        if (response.status === 200) {
            const data = response?.data ?? [];
            setState(prev => ({ ...prev, data }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[ReportEmployees] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleFile = async ({ target }) => {
        showLoader(true);

        try {
            if (target.files?.length) {
                const file = target.files[0];
                const buffer = await file.arrayBuffer();
                const unit8 = new Uint8Array(buffer);
                const workbook = XLSX.read(unit8, { type: 'array' });

                const firstSheetName = workbook.SheetNames[0]
                /* Get worksheet */
                const worksheet = workbook.Sheets[firstSheetName]
                const info = XLSX.utils.sheet_to_json(worksheet, { raw: true });
                const data = [];
                info.map(item => {
                    if (item['Code']) {
                        data.push(item);
                    }

                    return false;
                });

                console.log(data);
                if (data.length < 1) {
                    showToast('File format not supported', 'error');
                    target.value = null;
                }

                setState(prev => ({ ...prev, fileData: data, rejected: [] }));
            }
        } catch (e) {
            console.log(e);
            showToast('Something went wrong', 'error');
        }

        showLoader(false);
    }

    const importData = async () => {
        showLoader(true);

        const response = await post('/employee/importData', { fileData: state.fileData });
        if (response.status === 200) {
            showToast(response.message);
        } else {
            setState(prev => ({ ...prev, rejected: response?.rejected ?? [] }));

            console.log('[ReportEmployees] importData error', response);
            helpers.failRequest(response, showToast, signOut);
        }

        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employees' />
            <Grid container>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 1'}</FormLabel>
                        <Autocomplete
                            options={state.CC1}
                            value={state.filter.cc1}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc1', value)}
                            renderInput={params => <TextField {...params} placeholder='CC1' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 2'}</FormLabel>
                        <Autocomplete
                            options={state.CC2}
                            value={state.filter.cc2}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc2', value)}
                            renderInput={params => <TextField {...params} placeholder='CC2' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Cost center 3'}</FormLabel>
                        <Autocomplete
                            options={state.CC3}
                            value={state.filter.cc3}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('cc3', value)}
                            renderInput={params => <TextField {...params} placeholder='CC3' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-employees`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data} name='Employee report'>
                            <ExcelColumn label='Code' value='code' />
                            <ExcelColumn label='Name' value='name' />
                            <ExcelColumn label='Base rate' value='base_rate' />
                            <ExcelColumn label='Indicator' value='indicator' />
                            <ExcelColumn label='Sede code' value='sede.code' />
                            <ExcelColumn label='Sede name' value='sede.name' />
                            <ExcelColumn label='Active' value='active' />
                            <ExcelColumn label='Is supplier' value='is_supplier' />
                            <ExcelColumn label='Payment' value='payment' />
                            <ExcelColumn label='Regular overtime' value='regular_overtime' />
                            <ExcelColumn label='Bonus payment' value='bonus_payment' />
                            <ExcelColumn label='Biweekly payment' value='biweekly_payment' />
                            <ExcelColumn label='CC1' value='cc1.code' />
                            <ExcelColumn label='CC2' value='cc2.code' />
                            <ExcelColumn label='CC3' value='cc3.code' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <ViewTitle title='Import employees' />
            <Grid container>
                <Grid item sm={6} md={4}>
                    <FormGroup>
                        <FormLabel>{'Upload file'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='file'
                                onChange={handleFile}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item sm={6} md={3} xl={1} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.search}
                        disabled={state.fileData.length < 1}
                        style={{ width: '100%' }}
                        onClick={importData}
                    >
                        {'Update data'}
                    </Button>
                </Grid>
            </Grid>

            <Avoided open={open} onClose={() => setOpen(false)} array={state.rejected} />

        </div>
    );
}

export default ReportEmployees;