import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FilledInput,
  Icon,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  AppBar,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import AuthContext from "../../context";
import { get, post, del, put } from "../../utils/api-services";
import helpers from "../../utils/helpers";
import ViewTitle from "../../common/view-title";
import { Autocomplete } from "@material-ui/lab";
import { getWeekNumber } from "./utils/getWeekNumber";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 20,
  },
  deleteReportButton: {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#c82333",
      borderColor: "#bd2130",
      boxShadow: "none",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: 10,
  },
  divider: {
    marginBottom: 10,
  },
  save: {
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    width: "40%",
    "&:hover": {
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
      boxShadow: "none",
    },
  },
  imagePreview: {
    width: "100%",
    height: "auto",
  },
  customDialog: {
    width: "80%",
    height: "80%",
    maxWidth: "none",
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  tableContainer: {
    marginTop: 20
  },
  tableContainerTab: {
    marginTop: 20,
  },
  controlTable: {
    width: "100%",
  },
  weeklyControlTable: {
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
  },
}));

const Control = () => {
  const { showToast, signOut, showLoader } = useContext(AuthContext);
  const classes = useStyles();
  const { pathname } = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const getDate = (params) => dayjs(params.value).format("MM/DD/YYYY");
  const getMoney = (params) => `$ ${params.value}`;
  const userInfoLocalStorage = JSON.parse(localStorage.getItem("@auth_user"));
  const supervisorName = `${userInfoLocalStorage.names} ${userInfoLocalStorage.surnames}`;

  const [state, setState] = useState({
    loading: true,
    data: [],
    dialogDeleteItem: false,
    updateDialog: false,
    productTypeNames: {},
    dataWeekly: [],
    detailTypes: [],
    cashDetail: [],
    rowToUpdate: {},
    control: {
      date: "",
      total: "",
      week: "",
      image: "",
      check_deposit_number: "",
      detail: "",
      detail_type: "",
    },
    imageContainter: false,
    selectedImage: "",
    savingReports: false,
    permissions: {},
    filter: {
      truckDateRange: `${dayjs()
        .subtract(7, "day")
        .format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
    },
  });

  const fetchOptions = async () => {
    try {
      const response = await get("/cashierControl/product");
      if (response.status === 200) {
        const products = response.data;
        const productTypes = [
          ...new Set(products.map((item) => item.product_type)),
        ];

        const namesByProductType = productTypes.reduce((acc, type) => {
          acc[type.toLocaleLowerCase()] = products
            .filter((product) => product.product_type === type)
            .map((product) => product.name);
          return acc;
        }, {});

        setState((prev) => ({
          ...prev,
          detailsList: productTypes,
          productTypeNames: namesByProductType,
        }));
      }
      const responseCash = await get("/cashierControl/details");
      if (responseCash.status === 200) {
        const cashDetail = responseCash.data;

        setState((prev) => ({
          ...prev,
          cashDetail: cashDetail,
        }));
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    window.document.title = "Cashier";
    validatePermissions();
    console.log(state)
    fetchOptions();
  }, []);

  const validatePermissions = () => {
    const permissions = helpers.getPermissions(pathname);
    setState((prev) => ({ ...prev, permissions }));

    permissions.read_attr
      ? makeRequest()
      : setState((prev) => ({ ...prev, loading: false }));
  };

  const makeRequest = async () => {
    showLoader(true);
    const response = await get("/cashierControl/index", {
      idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
    });
    const responseWeekly = await get("/cashierWeeklyControl/index", {
      idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
    });
    if (response.status === 200) {
      setState((prev) => ({ ...prev, loading: false, data: response.data }));
    } else {
      console.log("[CashierControl] MakeRequest error", response);
      helpers.failRequest(response, showToast, signOut);
    }

    if (responseWeekly.status === 200) {
      setState((prev) => ({
        ...prev,
        loading: false,
        dataWeekly: responseWeekly.data,
        control: {
          date: "",
          total: "",
          week: "",
          image: "",
          check_deposit_number: "",
          detail: prev.control.detail,
          detail_type: prev.control.detail_type,
        },
      }));
    } else if (responseWeekly.status === 204) {
      setState((prev) => ({
        ...prev,
        loading: false,
        dataWeekly: [""],
      }));
    } else if (responseWeekly.status === 404) {
      setState((prev) => ({ ...prev, loading: false, dataWeekly: [] }));
    } else {
      helpers.failRequest(responseWeekly, showToast, signOut);
    }
    showLoader(false);
  };

  const handleChange = ({ target }) =>
    setState((prev) => ({
      ...prev,
      control: { ...prev.control, [target.name]: target.value },
    }));

  const handleUpdateChange = ({ target }) => {
    if (target.name === "total") {
      setState((prev) => ({
        ...prev,
        rowToUpdate: { ...prev.rowToUpdate, purchase: target.value },
      }));
    }
    setState((prev) => ({
      ...prev,
      rowToUpdate: { ...prev.rowToUpdate, [target.name]: target.value },
    }));
  };

  const handleAutocomplete = (key, value) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setState((prev) => ({
      ...prev,
      control: {
        ...prev.control,
        date: "",
        total: "",
        week: "",
        image: null,
        check_deposit_number: "",
        detail_type: "",
        [key]: value,
      },
    }));
  };

  const handleUpdateAutocompleteProduct = (key, value) => {
    setState((prev) => ({
      ...prev,
      rowToUpdate: {
        ...prev.rowToUpdate,
        detail_type: "",
        [key]: value,
      },
    }));
  };

  const handleAutocompleteProduct = (key, value) => {
    setState((prev) => ({
      ...prev,
      control: {
        ...prev.control,
        [key]: value,
      },
    }));
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0] ?? null;

    setState((prev) => ({
      ...prev,
      control: { ...prev.control, image: null },
    }));

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setState((prev) => ({
          ...prev,
          control: { ...prev.control, image: reader.result },
        }));
      };
    } else {
      console.log("No file selected");
    }
  };

  const validateFields = (control) => {
    const { date, detail, check_deposit_number, total, image } = control;
    let missingFields = [];

    if (!date) missingFields.push("Date");
    if (!detail) missingFields.push("Detail");
    if (total < 0) missingFields.push("Total-");

    switch (detail) {
      case "Deposit":
      case "Deposit Balance":
        if (!check_deposit_number) missingFields.push("Check/Deposit Number");
        if (!total) missingFields.push("Total");
        if (!image) missingFields.push("Image");
        break;
      default:
        if (!total) missingFields.push("Total");
        break;
    }

    return missingFields;
  };

  const save = async () => {
    const missingFields = validateFields(state.control);

    if (missingFields.length > 0) {
      const filteredMissingFields = missingFields.filter(
        (item) => item !== "Total-"
      );

      let errorMessage = "";

      if (filteredMissingFields.length > 0) {
        errorMessage = `The following fields are required: ${filteredMissingFields.join(
          ", "
        )}`;
      }

      if (missingFields.includes("Total-")) {
        if (errorMessage) {
          errorMessage += ` and Total must be greater than zero.`;
        } else {
          errorMessage = ` Total must be greater than zero.`;
        }
      }

      showToast(errorMessage, "error");
      return;
    }

    const cashierControlReport = {
      ...state.control,
      idsupervisor: userInfoLocalStorage.iduser,
      week: getWeekNumber(state.control.date),
    };
    try {
      const saveStatus = await post(
        "/cashierControl/store",
        cashierControlReport
      );
      if (saveStatus.status === 200) {
        const updatedResponse = await get("/cashierControl/index", {
          idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
        });

        const responseWeekly = await get("/cashierWeeklyControl/index", {
          idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
        });

        if (updatedResponse.status === 200) {
          setState((prev) => ({
            ...prev,
            dataWeekly: responseWeekly.data,
          }));
        } else {
          helpers.failRequest(updatedResponse, showToast, signOut);
        }

        let message = "";
        switch (state.control.detail) {
          case "Deposit":
            message = "entry has been registered";
            break;
          case "Deposit Balance":
            message = "has been successfully verified and saved!";
            break;
          default:
            message = "detail has been entered correctly";
        }
        showToast(`${state.control.detail} ${message}`, "success");
        setState((prev) => ({
          ...prev,
          control: {
            date: "",
            total: "",
            week: "",
            image: null,
            check_deposit_number: "",
            detail: prev.control.detail,
            detail_type: prev.control.detail_type,
          },
        }));
        fileInputRef.current.value = "";
        makeRequest();
      } else {
        helpers.failRequest(saveStatus, showToast, signOut);
      }
    } catch (error) {
      console.error("[CashierControl] Error", error);
      showToast("An error occurred while saving the data.");
    }
  };

  const columnsWeeklyReport = [
    {
      field: "week",
      headerName: "WK No",
      flex: 0.8,
      minWidth: 75,
    },
    {
      field: "cash",
      headerName: "Cash",
      flex: 0.8,
      minWidth: 75,
      valueGetter: getMoney,
    },
    {
      field: "difference",
      headerName: "Deposit Balance",
      flex: 0.8,
      minWidth: 75,
      valueGetter: (params) => `$ ${Math.abs(params.value).toFixed(2)}`,
    },
    {
      field: "purchase",
      headerName: "Purchases",
      flex: 0.8,
      minWidth: 75,
      valueGetter: getMoney,
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 0.8,
      minWidth: 75,
      valueGetter: getMoney,
    },
  ];

  const handleClickOpenDeleteItem = (params) => {
    setState((prev) => ({
      ...prev,
      itemToDelete: params,
      dialogDeleteItem: true,
    }));
  };

  const handleCloseDeleteItem = () => {
    setState((prev) => ({ ...prev, dialogDeleteItem: false }));
  };

  const handleDeleteClick = async () => {
    try {
      const response = await del(
        `/cashierControl/${state.itemToDelete.idcashier_control}`,
        {
          idsupervisor: JSON.parse(localStorage.getItem("@auth_user")).iduser,
        }
      );
      if (response.status === 200) {
        setState((prev) => ({
          ...prev,
          data: prev.data.filter(
            (item) =>
              item.idcashier_control !== state.itemToDelete.idcashier_control
          ),
          dialogDeleteItem: false,
        }));
        makeRequest();
        showToast(response.message, "success");
      } else {
        setState((prev) => ({
          ...prev,
          dialogDeleteItem: false,
        }));
        showToast(response.message, "error");
      }
    } catch (error) {
      console.error("Error deleting the row", error);
    }
  };

  const columns = [
    {
      field: ".",
      flex: 0.4,
      minWidth: 5,
      renderCell: (params) => (
        <Icon
          onClick={(event) => {
            event.stopPropagation();
            handleClickOpenDeleteItem(params.row);
          }}
        >
          {"delete"}
        </Icon>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 10,
      valueGetter: getDate,
    },
    {
      field: "week",
      headerName: "Week",
      flex: 0.85,
      minWidth: 10,
    },
    {
      field: "detail",
      headerName: "Detail",
      flex: 2,
      minWidth: 75,
    },
    {
      field: "detail_type",
      headerName: "Detail Type",
      flex: 2,
      minWidth: 75,
    },
    {
      field: "check_deposit_number",
      headerName: "Check/Deposit No",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "cash",
      headerName: "Cash",
      flex: 1.2,
      minWidth: 100,
      renderCell: (params) => {
        const value = params.value;
        const isDepositBalance = params.row.detail === "Deposit Balance";

        if (value === 0 && isDepositBalance) {
          return <span style={{ color: "red" }}>$ 0.00</span>;
        }

        if (value === 0) return "";

        const isNegative = value < 0;
        const displayValue = isNegative
          ? `(${Math.abs(value).toFixed(2)})`
          : value.toFixed(2);

        return (
          <span style={{ color: isNegative ? "red" : "inherit" }}>
            $ {displayValue}
          </span>
        );
      },
    },
    {
      field: "purchase",
      headerName: "Purchases",
      flex: 1.2,
      minWidth: 100,
      renderCell: (params) => {
        const value = params.value;
        if (value === 0) return "";

        const isNegative = value < 0;
        const displayValue = isNegative
          ? `(${Math.abs(value).toFixed(2)})`
          : value.toFixed(2);

        return (
          <span style={{ color: isNegative ? "red" : "inherit" }}>
            $ {displayValue}
          </span>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1.2,
      minWidth: 100,
      renderCell: (params) => {
        const value = params.value;

        const isNegative = value < 0;
        const displayValue = isNegative
          ? `(${Math.abs(value).toFixed(2)})`
          : value.toFixed(2);

        return (
          <span style={{ color: isNegative ? "red" : "inherit" }}>
            $ {displayValue}
          </span>
        );
      },
    },
    {
      field: "image",
      headerName: "Photo",
      flex: 1,
      minWidth: 75,
      renderCell: (params) =>
        params.value ? (
          <Button onClick={() => handleClickOpen(params.value)}>
            <Icon>{"camera_alt"}</Icon>
          </Button>
        ) : (
          " "
        ),
    },
  ];

  const handleRowClick = (params) => {
    const { detail, balance, purchase } = params.row;

    if (detail !== "Deposit" && detail !== "Deposit Balance") {
      setState((prev) => ({
        ...prev,
        rowToUpdate: {
          ...params.row,
          total: balance || purchase,
        },
        updateDialog: true,
      }));
    }
  };

  const handleClickOpen = (img) => {
    setState((prev) => ({
      ...prev,
      imageContainter: true,
      selectedImage: img,
    }));
  };
  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      imageContainter: false,
      updateDialog: false,
    }));
  };
  const updateItem = async () => {
    try {
      const response = await put(
        `/cashierControl/${state.rowToUpdate.idcashier_control}`,
        { ...state.rowToUpdate }
      );

      if (response.status === 200) {
        setState((prev) => ({
          ...prev,
          updateDialog: false,
        }));
        showToast(response.message, "success");
      } else {
        showToast(response.message, "error");
      }
      makeRequest();
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.root}>
      <ViewTitle sedeFlag title="Cashier Control" />
      <Divider className={classes.divider} />

      <Grid container spacing={2} className={classes.inputContainer}>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Supervisor"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="text"
                placeholder={"Supervisor"}
                name={"supervisor"}
                value={supervisorName}
                disabled
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Detail Type"}</FormLabel>
            <Autocomplete
              options={[...state.cashDetail]}
              value={state.control.detail?.name}
              getOptionLabel={(option) => option?.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                handleAutocomplete("detail", value?.name);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Detail" />
              )}
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display:
              state.control.detail === "Deposit" ||
              state.control.detail === "Deposit Balance"
                ? "none"
                : "block",
          }}
        >
          <FormGroup>
            <FormLabel>{"Detail Product"}</FormLabel>
            <Autocomplete
              options={
                state.productTypeNames?.[state.control.detail?.toLowerCase()] ||
                []
              }
              getOptionSelected={(option, value) => {
                if (!option || !value) return false;
                return option.id === value.idproduct;
              }}
              value={state.control.detail_type}
              onChange={(event, value) => {
                handleAutocompleteProduct("detail_type", value);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Detail Product" />
              )}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Total"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="number"
                value={state.control.total}
                placeholder={"Total"}
                name={"total"}
                onChange={handleChange}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Date"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="date"
                placeholder="Date"
                name="date"
                value={state.control.date}
                onChange={handleChange}
                inputProps={{ max: dayjs().format("YYYY-MM-DD") }}
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>{"Week"}</FormLabel>
            <FormControl variant="filled" component={Box} width="100%">
              <FilledInput
                autoComplete="off"
                type="number"
                placeholder={"Week"}
                name={"week"}
                value={getWeekNumber(state.control.date)}
                disabled
              />
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display:
              state.control.detail === "Deposit" ||
              state.control.detail === "Deposit Balance"
                ? "block"
                : "none",
          }}
        >
          <FormGroup>
            <FormLabel>{"Check/Deposit No"}</FormLabel>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl variant="filled" component={Box} style={{ flex: 8 }}>
                <FilledInput
                  autoComplete="off"
                  type="text"
                  placeholder={"Check/Deposit No"}
                  value={state.control.check_deposit_number}
                  name={"check_deposit_number"}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                variant="filled"
                component={Box}
                style={{
                  flex: 1,
                  marginLeft: "10px",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <Button
                  onClick={handleButtonClick}
                  style={{
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Icon>{"camera_alt"}</Icon>
                </Button>
              </FormControl>
            </div>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          className={classes.save}
          onClick={save}
          style={{ width: "15%" }}
        >
          {"Save"}
        </Button>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.tableContainerTab}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="Show data"
          >
            <Tab label="Cashier control" {...a11yProps(0)} />
            <Tab label="Weekly balance control" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        {tabValue === 0 ? (
          <div className={classes.tableContainer}>
            <DataGrid
              className={classes.controlTable}
              rows={state.data}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5, 10, 20, 50]}
              density="compact"
              autoHeight
              getRowId={(row) => row.idcashier_control}
              onRowClick={handleRowClick}
            />
          </div>
        ) : (
          <div className={classes.tableContainerTab}>
            <DataGrid
              className={classes.weeklyControlTable}
              rows={Array.isArray(state.dataWeekly) ? state.dataWeekly : []}
              columns={columnsWeeklyReport}
              pageSize={20}
              density="compact"
              autoHeight
              rowsPerPageOptions={[5, 10, 20, 50]}
              getRowId={(row) => row.id_weekly_report}
            />
          </div>
        )}
      </div>

      <Dialog
        open={state.updateDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>{"Detail Type"}</FormLabel>
                <Autocomplete
                  options={state.cashDetail.filter(
                    (option) =>
                      option.name !== "Deposit" &&
                      option.name !== "Deposit Balance"
                  )}
                  value={
                    state.cashDetail.find(
                      (option) => option.name === state.rowToUpdate.detail
                    ) || null
                  }
                  getOptionLabel={(option) => option?.name ?? ""}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(event, value) => {
                    handleUpdateAutocompleteProduct("detail", value?.name);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Detail" />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>{"Total"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <FilledInput
                    autoComplete="off"
                    type="number"
                    value={Math.abs(state.rowToUpdate.purchase)}
                    name={"total"}
                    onChange={handleUpdateChange}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            {state.rowToUpdate.detail === "Deposit" ||
            state.rowToUpdate.detail === "Deposit Balance" ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} md={10}>
                  <FormGroup>
                    <FormLabel>{"Detail Product"}</FormLabel>
                    <Autocomplete
                      options={
                        state.productTypeNames?.[
                          state.rowToUpdate.detail?.toLowerCase()
                        ] || []
                      }
                      getOptionSelected={(option, value) => {
                        if (!option || !value) return false;
                        return option.id === value.idproduct;
                      }}
                      value={state.rowToUpdate.detail_type}
                      onChange={(event, value) => {
                        handleUpdateAutocompleteProduct("detail_type", value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Detail Product" />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={2}>
              <FormGroup>
                <FormLabel>{"Week"}</FormLabel>
                <FormControl variant="filled" component={Box} width="100%">
                  <FilledInput
                    autoComplete="off"
                    type="number"
                    placeholder={"Week"}
                    name={"week"}
                    value={getWeekNumber(state.rowToUpdate.date)}
                    disabled
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateItem}>Update</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.imageContainter}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.customDialog }}
      >
        <DialogContent>
          <DialogContentText>
            <img
              src={state.selectedImage}
              alt="Selected"
              className={classes.imagePreview}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={state.dialogDeleteItem}
        onClose={handleCloseDeleteItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <strong>You´re going to delete this item:</strong>{" "}
            </p>
            <p>
              {" "}
              <strong>
                Date: {dayjs(state.itemToDelete?.date).format("MM/DD/YYYY")}
              </strong>
            </p>
            <p>
              {" "}
              <strong>
                {state.itemToDelete?.detail} {state.itemToDelete?.detail_type}
              </strong>
            </p>
            <p>
              {" "}
              <strong>Cash: {state.itemToDelete?.cash}</strong>
            </p>
            <p>
              {" "}
              <strong>Pruchase: {state.itemToDelete?.purchase}</strong>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteItem}>Disagree</Button>
          <Button
            className={classes.deleteReportButton}
            style={{ color: "white" }}
            onClick={handleDeleteClick}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Control;
