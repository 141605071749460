import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid, TextField, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';

import themeColors from '../../assets/colors';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    }
}));

const RecipeCreate = ({ open, onClose }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const history = useHistory();
    const isMounted = useRef(false);
    const [state, setState] = useState({
        departments: [],
        recipe: {
            code: '',
            description: '',
            department: { id: 0, name: 'Choose a department' },
            pack_qty: 1,
            average_weight: 0,
            image: '',
            wreath_style: ''
        }
    });

    useEffect(() => {
        if (open && !isMounted.current) {
            makeRequest();
            isMounted.current = true;
        }
    }, [open]);

    useEffect(() => {
        const { department } = state.recipe;
        if (department?.name !== 'Wreath') {
            setState(prev => ({ ...prev, recipe: { ...prev.recipe, wreath_style: '' } }));
        }
    }, [state.recipe.department]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/recipe/create');
        if (response.status === 200) {
            setState(prev => ({ ...prev, departments: response?.departments ?? [] }));
        } else {
            console.log('[RecipeCreate] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, recipe: { ...prev.recipe, [key]: value } }));

    const handleChange = ({ target }) => setState(prev => ({ ...prev, recipe: { ...prev.recipe, [target.name]: target.value } }));

    const handleChangeImage = ({ target }) => {
        const file = target?.files[0] ?? '';
        if (file) {

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                setState(prev => ({ ...prev, recipe: { ...prev.recipe, image: reader.result } }));
            }
        } else {
            setState(prev => ({ ...prev, recipe: { ...prev.recipe, image: '' } }));
        }
    }

    const validateForm = () => {
        const { code, description, department, pack_qty, image } = state.recipe;

        if (code.trim() && description.trim() && department.id && pack_qty && image) {
            const cond = window.confirm('Confirm if all data is correct');
            if (cond) {
                store();
            }
        } else {
            showToast('All fields is required', 'error');
        }
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/recipe/store', state.recipe);
        if (response.status === 200) {
            history.push(`/bom/recipe/detail?id=${response.id}`);
            showToast(response.message);
        } else {
            console.log('[RecipeCreate] store error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Create recipe'}</Typography>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={state.recipe.code}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <FormGroup>
                            <FormLabel>{'Description'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Description'
                                    name='description'
                                    value={state.recipe.description}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Department'}</FormLabel>
                            <Autocomplete
                                options={state.departments}
                                value={state.recipe.department}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocomplete('department', value)}
                                renderInput={params => <TextField {...params} placeholder='Department' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Pack qty'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Pack qty'
                                    name='pack_qty'
                                    value={state.recipe.pack_qty}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Average weight'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Average weight'
                                    name='average_weight'
                                    value={state.recipe.average_weight}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Wreath style'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <Select
                                    value={state.recipe.wreath_style}
                                    name='wreath_style'
                                    onChange={handleChange}
                                    disabled={state.recipe.department?.name !== 'Wreath'}
                                >
                                    <MenuItem value={''}><em>{'N/A'}</em></MenuItem>
                                    <MenuItem value={'Crimp'}>{'Crimp'}</MenuItem>
                                    <MenuItem value={'Hand tie'}>{'Hand tie'}</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Image'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    type='file'
                                    inputProps={{ accept: 'image/*' }}
                                    placeholder='Image'
                                    id='image'
                                    onChange={handleChangeImage}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={validateForm}
                    >
                        {'Store'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default RecipeCreate;