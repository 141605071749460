import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import DateRange from "../../common/date-range";
import Trash from "../../common/trash";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import { get, post, del } from "../../utils/api-services";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonsContainerRight: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
	},
	search: {
		alignSelf: "flex-end",
		marginBottom: 20,
		marginLeft: 15,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	payButton: {
		width: "100%",
		backgroundColor: "#029e1c",
		borderColor: "#018217",
		"&:hover": {
			backgroundColor: "#018217",
			borderColor: "#018217",
			boxShadow: "none",
		}
	},
	tableContainer: {
		width: "100%",
	},
	dividerClass: {
		marginTop: 10,
		marginBottom: 30
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	rowText: {
		display: "inherit",
		flexDirection: "inherit",
		alignItems: "inherit",
	},
}));

export default function DeliveryTicketsList({ permissions, isPayment = false}) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const [state, setState] = useState({
		loading: true,
		customers: [],
		invoiceStatus: [],
		paidStatus: [],
		filter: {
			code: "",
			startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
			dateRange: `${dayjs().subtract(1, "day").format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
			customer: { id: 0, name: "All customers" },
			invoice: { id: 0, name: "All invoice status" },
			paid: { id: 0, name: "All paid status" },
		},
		tickets: [],
		rangeModal: {
			open: false,
			range: [
				{
					startDate: new Date(dayjs().subtract(1, "day").toDate()),
					endDate: new Date(),
					key: "main",
				},
			],
		},
		permissions: {},
		selectedRows: [],
		paymentDate: ""
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		window.document.title = isPayment ? "Payment Received (Delivery Tickets)" : "Delivery Ticket List";

		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);
		const response = await get("/farmForest/delivery", state.filter);
		if (response.status === 200) {
			setState((prev) => ({
				...prev,
				loading: false,
				tickets: response?.data ?? [],
				customers: response?.customers ?? [],
				invoiceStatus: response?.invoiceStatus ?? [],
				paidStatus: response?.paidStatus ?? [],
			}));
		} else {
			console.log("[DeliveryTicketsList] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleDateChange = (item) => {
		const { startDate, endDate } = item.main;
		const dateRange = `${dayjs(startDate.toISOString()).format("MM/DD/YYYY")} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
		setState((prev) => ({
			...prev,
			filter: {
				...prev.filter,
				dateRange,
				startDate: dayjs(startDate.toISOString()).format("YYYY-MM-DD"),
				endDate: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
			},
			rangeModal: { ...prev.rangeModal, range: [item.main] },
		}));
	};

	const handleModal = () => setState((prev) => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

	const handleChange = ({ target: { name, value } }) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

	const handleChangeAutocomplete = (key, value) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

	const handlePayentDate = ({ target: { name, value } }) => setState((prev) => ({ ...prev, paymentDate: value }));

	const handleSelectionRows = (ids) => { setState((prev) => ({ ...prev, selectedRows: ids})); }

	const search = async () => {
		showLoader(true);
		const response = await get("/farmForest/delivery/search", state.filter);
		if (response.status === 200) {
			setState((prev) => ({ ...prev, tickets: response?.data ?? [] }));

			if (!response?.data[0]) showToast("There are no delivery tickets with these filters", "warning");
		} else {
			console.log("[DeliveryTicketsSearch] search error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const updatepaymentDate = async () => {
		if(state.paymentDate === ""){
			showToast("Payment date must not be empty", "error")
			return
		}

		const tempPaymentDate = state.paymentDate;
		showLoader(true);
		const response = await post("/farmForest/delivery/bulkUpdate", {idRows: state.selectedRows, paymentDate: new dayjs(tempPaymentDate).format("YYYY-MM-DD")});
		if (response.status === 200) {
			// Change state Not paid to Paid 
			let temp = state.tickets
			temp.forEach((item) => {
				if(response?.data.includes(item.idff_delivery)){
					item.payment_status = "Paid";
					item.date_payment = new dayjs(tempPaymentDate).format("MM/DD/YYYY");
				} 
				return item;
			})
			// Remove paid tickets in where filter state is not paid
			let tempt = temp.filter((item) => response?.data.includes(item.idff_delivery) && state.filter.paid.id === "Not paid" ? false : true)
			setState((prev) => ({ ...prev, tickets: tempt }));

			if (response?.errors.length > 0) {
				showToast(`${response?.errors.length} tickets not updated`, "warning")
			}else{
				setState((prev) => ({ ...prev, tickets: [] }));
				setState((prev) => ({ ...prev, tickets: tempt, selectedRows: [], paymentDate: "" }));
				showToast("All payment dates updated successfully")
			};
		} else {
			console.log("[DeliveryTicketsPayment] update error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	}

	const destroy = async (id) => {
		if (window.confirm("Are you sure you want to delete this ticket?")) {
			showLoader(true);
			const response = await del(`/farmForest/delivery/${id}`);
			if (response.status === 200) {
				makeRequest();
				showToast(response.message);
			} else {
				console.log("[DeliveryTicketsDestroy] destroy error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const renderDate = (params) => (params.value ? dayjs(params.value).format("MM/DD/YYYY"): "");

	const valueGetter = (params) => {
		return params.value === null ? "" : params.value.name;
	};

	const renderCell = ({ row }) => {
		return (
		<div>
			<Tooltip title="Show detail" placement="right">
				<Link to={`/farmForest/deliveryTickets/${row.idff_delivery}`} target="_blank">
					<IconButton>
						<Icon>{"visibility"}</Icon>
					</IconButton>
				</Link>
			</Tooltip>

			<Link to={`/history/ff_delivery/${row.idff_delivery}`} target="_blank">
				<Tooltip title="Show history" placement="top">
					<IconButton>
						<Icon>{"history"}</Icon>
					</IconButton>
				</Tooltip>
			</Link>

			{state.permissions?.delete_attr && (
			<Tooltip title="Delete" placement="top">
				<IconButton onClick={e => destroy(row.idff_delivery)}>
					<Icon>{"delete"}</Icon>
				</IconButton>
			</Tooltip>
			)}
		</div>
		);
	};

	const columns = [
		{ field: "delivery_number", headerName: "No. ticket", flex: 0.5 },
		{ field: "total_invoice", headerName: "Total", flex: 0.7 },
		{ field: "date_created", headerName: "Date", flex: 1, valueGetter: renderDate },
		{ field: "customer", headerName: "Customer", flex: 1, valueGetter },
		{ field: "invoice_status", headerName: "Invoiced Status", flex: 0.7 },
		{ field: "payment_status", headerName: "Paid Status", flex: 0.7 },
		{ field: "date_payment", headerName: "Paid Date", flex: 0.7, valueGetter: renderDate },
		{ field: "Actions", headerName: "Actions", flex: 1,minWidth: 150, renderCell },
	];

	if (state.loading) {
		return <Loader />;
	}

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"No. ticket"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="No. ticket" name="code" onChange={handleChange} value={state.filter.code} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup onClick={handleModal}>
						<FormLabel>{"Date range"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput readOnly autoComplete="off" type="text" placeholder="Date range" name="range" value={state.filter.dateRange} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Customer name"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.customers}
							value={state.filter.customer}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("customer", value)}
							renderInput={(params) => <TextField {...params} placeholder="Customer" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Invoice Status"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.invoiceStatus}
							value={state.filter.invoice}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("invoice", value)}
							renderInput={(params) => <TextField {...params} placeholder="Invoice status" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Paid Status"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.paidStatus}
							value={state.filter.paid}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("paid", value)}
							renderInput={(params) => <TextField {...params} placeholder="Paid status" />}
							disableClearable={true}
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<div className={classes.buttonsContainerRight}>
				{state.permissions.delete_attr && <Trash table="ff_delivery" id="idff_delivery" onRefresh={makeRequest} />}
				<Button variant="contained" className={classes.search} onClick={search}>
					{"Search"}
				</Button>
			</div>

			<div hidden={!isPayment}>
				<Divider className={classes.dividerClass}/>
				<Grid container>
					<Grid item xs={6} lg={3}>
						<FormGroup>
							<FormLabel>{"Payment Date"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="date" placeholder="Payment Date" name="payment_date" onChange={handlePayentDate} value={state.paymentDate} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={6} lg={2} className={classes.buttonContainer} >
							<Button variant="contained" className={classes.payButton} onClick={updatepaymentDate} disabled={state.selectedRows.length <= 0}>
								{"Set Payment Date"}
							</Button>
					</Grid>
				</Grid>
			</div>

			<div className={classes.tableContainer}>
				<DataGrid checkboxSelection={isPayment} onSelectionModelChange={handleSelectionRows} disableSelectionOnClick rows={state.tickets} columns={columns} pageSize={45} density="compact" autoHeight getRowId={(row) => row.idff_delivery} />
			</div>

			<DateRange open={state.rangeModal.open} range={state.rangeModal.range} onClose={handleModal} onChange={handleDateChange} />
		</div>
	);
}
