import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Divider, IconButton, Icon, Collapse, Button, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    divider: {
        marginBottom: 24
    },
    tableContainer: {
        width: '100%',
        marginTop: 20
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    }
}));

const ProductionsDesktop = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [showFilters, setShowFilters] = useState(false);
    const [state, setState] = useState({
        loading: true,
        departments: [],
        employees: [],
        stations: [],
        product_sales: [],
        productions: [],
        filter: {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            departmentf: { id: 0, name: 'All departments' },
            productf: { id: 0, name: 'All product sales' },
            employeef: { id: 0, name: 'All employees' }
        },
        production: {
            idproduction: 0,
            department: {},
            date: dayjs().format('YYYY-MM-DD'),
            stacion: {},
            section: '',
            product: {},
            position: 0,
            employee: {},
            weight: 0,
            ticket: '',
            product_qty: 0,
            qty: 0,
            total_qty: 0
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        validatePermissions();
    }, []);

    useEffect(() => {
        const { qty, department, product } = state.production;
        const product_qty = department?.production_pack ? 1 : product?.pack_qty ?? 1;

        const total_qty = (Number(product_qty) * Number(qty)).toFixed(0);
        setState(prev => ({ ...prev, production: { ...prev.production, total_qty, product_qty } }));

    }, [state.production.qty, state.production.department, state.production.product]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productions', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                departments: response?.departments ?? [],
                employees: response?.employees ?? [],
                stations: response?.stations ?? [],
                product_sales: response?.products ?? [],
                productions: response?.data ?? [],
                production: {
                    ...prev.production,
                    department: response?.departments[0] ?? {},
                    employee: response?.employees[0] ?? {},
                    stacion: response?.stations[0] ?? {},
                    product: response?.products[0] ?? {},
                }
            }));
        } else {
            console.log('[Productions] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, production: { ...prev.production, [target.name]: target.value } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, production: { ...prev.production, [key]: value } }));

    const handleAutocompleteFilter = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const validateForm = (option) => {
        const { idproduction, department, stacion, section, product, position, employee, total_qty, weight } = state.production;

        switch (option) {
            case 'update':
                const data = state.productions.filter(value => value.idproduction === idproduction);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.production);
                    if (before !== after) {
                        if (department?.id && stacion?.id && section.trim() && product.id && Number(position) && employee?.id && Number(total_qty) && Number(weight)) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some production', 'error');
                    return false;
                }

            case 'store':

                if (department?.id && stacion?.id && section.trim() && product.id && Number(position) && employee?.id && Number(total_qty) && Number(weight)) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idproduction === 0) {
                    showToast('Select some production', 'error');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this production?');

                return Boolean(idproduction && cond);

            default:
                return false;
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, production: { ...prev.production, position: 0, employee: prev.employees[0], weight: 0, ticket: '', product_qty: 0, qty: 0, total_qty: 0 } }));
    }

    const clearAll = () => {
        setState(prev => ({
            ...prev,
            production: {
                ...prev.production,
                idproduction: 0,
                department: prev.departments[0],
                date: dayjs().format('YYYY-MM-DD'),
                stacion: prev.stations[0],
                section: '',
                product: prev.product_sales[0],
                position: 0,
                employee: prev.employees[0],
                weight: 0,
                ticket: '',
                product_qty: 0,
                qty: 0,
                total_qty: 0
            }
        }));
    }

    const store = async () => {
        const { filter, production } = state;
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/production/store', { ...filter, ...production });
            if (response.status === 200) {
                setState(prev => ({ ...prev, productions: response?.data ?? prev.productions }));
                clear();

                showToast(response.message);
            } else {
                console.log('[Productions] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduction } = state.production;
        const { filter, production } = state;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/production/${idproduction}/update`, { ...filter, ...production });
            if (response.status === 200) {
                setState(prev => ({ ...prev, productions: response?.data ?? prev.productions }));
                showToast(response.message);
            } else {
                console.log('[Productions] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idproduction } = state.production;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/production/${idproduction}`, state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, productions: response?.data ?? prev.productions }));
                clearAll();

                showToast(response.message);
            } else {
                console.log('[Productions] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const search = async () => {
        showLoader(true);
        const response = await get('/production/search', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, productions: response.data }));
            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[Productions] search error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const getPosition = ({ row }) => `${row.station}${row.section}${row.position}`;

    const getName = ({ value }) => value?.name ?? 'N/A';

    const getEmployee = ({ value, row }) => value?.name ? `${!row.is_alone ? '*' : ''}${value.name}` : 'N/A';

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/productions/${row.idproduction}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'date', headerName: 'Date', flex: 0.4 },
        { field: 'position', headerName: 'Position', flex: 0.4, valueGetter: getPosition },
        { field: 'stored_by', headerName: 'Stored by', flex: 0.6, valueGetter: getName },
        { field: 'product', headerName: 'Product sale', flex: 1.3, valueGetter: getName },
        { field: 'department', headerName: 'Department', flex: 0.6, valueGetter: getName },
        { field: 'employee', headerName: 'Employee', flex: 0.8, valueGetter: getEmployee },
        { field: 'product_qty', headerName: 'Product qty', flex: 0.4 },
        { field: 'qty', headerName: 'Qty', flex: 0.4 },
        { field: 'total_qty', headerName: 'Total qty', flex: 0.4 },
        { field: 'actions', headerName: 'Actions', flex: 0.4, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }

    return (
        <>
            <Grid container>
                <Grid item sm={4} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Department'}</FormLabel>
                        <Autocomplete
                            options={state.departments}
                            value={state.production.department}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('department', value)}
                            renderInput={params => <TextField {...params} placeholder='Department' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item sm={4} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.production.date}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item sm={4} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Station'}</FormLabel>
                        <Autocomplete
                            options={state.stations}
                            value={state.production.stacion}
                            getOptionLabel={option => option?.name?.toString() ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('stacion', value)}
                            renderInput={params => <TextField {...params} placeholder='Station' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={4} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Section'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Section'
                                name='section'
                                value={state.production.section}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item sm={8} md={6} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Product sale'}</FormLabel>
                        <Autocomplete
                            options={state.product_sales}
                            value={state.production.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product sale' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Average weight'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                disabled
                                type='text'
                                placeholder='Average weight'
                                value={state.production.product?.average_weight ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={4} md={2}>
                    <FormGroup>
                        <FormLabel>{'Position'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Position'
                                name='position'
                                value={state.production.position}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item sm={8} md={5}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.production.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={4} md={2}>
                    <FormGroup>
                        <FormLabel>{'Weight'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Weight'
                                name='weight'
                                value={state.production.weight}
                                onChange={handleChange}
                                inputProps={{ style: { backgroundColor: (Number(state.production.weight) > Number(state.production.product?.average_weight)) ? '#F8E0E0' : '#E0F8E0' } }}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={4} md={3}>
                    <FormGroup>
                        <FormLabel>{'Ticket'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled={!state.production.department?.use_ticket}
                                autoComplete='off'
                                type='text'
                                placeholder='No. ticket'
                                name='ticket'
                                value={state.production.ticket}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={4} md={3}>
                    <FormGroup>
                        <FormLabel>{'Product qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                name='product_qty'
                                value={state.production.product_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={4} md={3}>
                    <FormGroup>
                        <FormLabel>{'Quantity'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                placeholder='Quantity'
                                type='number'
                                name='qty'
                                value={state.production.qty}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={4} md={3}>
                    <FormGroup>
                        <FormLabel>{'Total qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                name='total_qty'
                                value={state.production.total_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <Divider />

            <div className={classes.row}>
                <h2>{'Filters'}</h2>
                <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <Icon>{showFilters ? 'expand_less' : 'expand_more'}</Icon>
                </IconButton>
            </div>
            <Collapse in={showFilters}>
                <Grid container>
                    <Grid item xs={6} lg={4}>
                        <FormGroup onClick={handleModal}>
                            <FormLabel>{'Date range'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Date range'
                                    value={state.filter.dateRange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Department'}</FormLabel>
                            <Autocomplete
                                options={state.departments}
                                value={state.filter.departmentf}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('departmentf', value)}
                                renderInput={params => <TextField {...params} placeholder='Department' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Product sale'}</FormLabel>
                            <Autocomplete
                                options={state.product_sales}
                                value={state.filter.productf}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('productf', value)}
                                renderInput={params => <TextField {...params} placeholder='Product sales' />}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <FormGroup>
                            <FormLabel>{'Employee'}</FormLabel>
                            <Autocomplete
                                options={state.employees}
                                value={state.filter.employeef}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(e, value) => handleAutocompleteFilter('employeef', value)}
                                renderInput={params => <TextField {...params} placeholder='Employee' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <div className={classes.buttonsContainer}>
                    <Button
                        variant='contained'
                        className={classes.search}
                        onClick={search}
                    >
                        {'Search'}
                    </Button>
                </div>
            </Collapse>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.productions}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduction}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, production: { ...row, ticket: '' } }))}
                />
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </>
    );
}

export default ProductionsDesktop;