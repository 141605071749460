import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dayjs from "dayjs";

import Loader from "../../common/loader";
import DateRange from "../../common/date-range";
import Trash from "../../common/trash";

import AuthContext from "../../context";
import helpers from "../../utils/helpers";
import { get, post, del } from "../../utils/api-services";

const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonsContainerRight: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
	},
	search: {
		alignSelf: "flex-end",
		marginBottom: 20,
		marginLeft: 15,
		backgroundColor: "#0d6efd",
		borderColor: "#0d6efd",
		"&:hover": {
			backgroundColor: "#0d6efd",
			borderColor: "#0d6efd",
			boxShadow: "none",
		},
	},
	payButton: {
		width: "100%",
		backgroundColor: "#029e1c",
		borderColor: "#018217",
		"&:hover": {
			backgroundColor: "#018217",
			borderColor: "#018217",
			boxShadow: "none",
		}
	},
	tableContainer: {
		width: "100%",
	},
	dividerClass: {
		marginTop: 10,
		marginBottom: 30
	}
}));

export default function BillsCuttingTicketsList({ permissions, isPayment = false }) {
	const { showToast, signOut, showLoader } = useContext(AuthContext);
	const classes = useStyles();
	const [state, setState] = useState({
		loading: true,
		suppliers: [],
		lands: [],
		states: [
			{id: 0, name: "All states"},
			{id: "Paid", name: "Paid"},
			{id: "Not paid", name: "Not paid"},],
		filter: {
			code: "",
			startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
			endDate: dayjs().format("YYYY-MM-DD"),
			dateRange: `${dayjs().subtract(7, "day").format("MM/DD/YYYY")} - ${dayjs().format("MM/DD/YYYY")}`,
			supplier: { id: 0, name: "All suppliers" },
			land: { id: 0, name: "All lands" },
			state: {id: "0", name: "All states"}
		},
		tickets: [],
		rangeModal: {
			open: false,
			range: [
				{
					startDate: new Date(dayjs().subtract(7, "day").toDate()),
					endDate: new Date(),
					key: "main",
				},
			],
		},
		permissions: {},
		selectedRows: [],
		paymentNumber: ""
	});

	useEffect(() => {
		setState((prev) => ({ ...prev, permissions }));
	}, [permissions]);

	useEffect(() => {
		window.document.title = isPayment ? "Payments Made (Bills and Cutting)" : "Bills and Cutting Ticket List";

		makeRequest();
	}, []);

	const makeRequest = async () => {
		showLoader(true);

		const response = await get("/farmForest/billsAndCuttingTickets", state.filter);
		if (response.status === 200) {
			setState((prev) => ({
				...prev,
				loading: false,
				tickets: response?.data ?? [],
				suppliers: response?.suppliers ?? [],
				lands: response?.lands ?? [],
			}));
		} else {
			console.log("[TicketsList] makeRequest error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const handleDateChange = (item) => {
		const { startDate, endDate } = item.main;
		const dateRange = `${dayjs(startDate.toISOString()).format("MM/DD/YYYY")} - ${dayjs(endDate.toISOString()).format("MM/DD/YYYY")}`;
		setState((prev) => ({
			...prev,
			filter: {
				...prev.filter,
				dateRange,
				startDate: dayjs(startDate.toISOString()).format("YYYY-MM-DD"),
				endDate: dayjs(endDate.toISOString()).format("YYYY-MM-DD"),
			},
			rangeModal: { ...prev.rangeModal, range: [item.main] },
		}));
	};

	const handleModal = () => setState((prev) => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

	const handleChange = ({ target: { name, value } }) => setState((prev) => ({ ...prev, filter: { ...prev.filter, [name]: value } }));

	const handleChangeAutocomplete = (key, value) => {
		if(value){
			setState((prev) => ({ ...prev, filter: { ...prev.filter, [key]: value } }));
		}else{
			setState((prev) => ({ ...prev, filter: { ...prev.filter, [key]: state[`${key}s`]?.[0] ?? {} } }));
		}
	}

	const handlePayentNumber = ({ target: { name, value } }) => setState((prev) => ({ ...prev, paymentNumber: value.trim() }));

	const handleSelectionRows = (ids) => { setState((prev) => ({ ...prev, selectedRows: ids})); }

	const search = async () => {
		showLoader(true);
		setState((prev) => ({ ...prev, tickets: [], selectedRows: [], paymentNumber: "" }));
		const response = await get("/farmForest/billsAndCuttingTickets/search", state.filter);
		if (response.status === 200) {
			setState((prev) => ({ ...prev, tickets: response?.data ?? [] }));

			if (!response?.data[0]) showToast("There are no 'bills and cutting' tickets with these filters", "warning");
		} else {
			console.log("[BillsAndCuttingTicketSearch] search error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	};

	const updatePaymentNumber = async () => {
		if(state.paymentNumber === ""){
			showToast("Payment number must not be empty", "error")
			return
		}
		showLoader(true);
		const response = await post("/farmForest/billsAndCuttingTickets/bulkUpdate", {idRows: state.selectedRows, paymentNumber: state.paymentNumber});
		if (response.status === 200) {
			// Change state Not paid to Paid 
			let temp = state.tickets
			temp.forEach((item) => response?.data.includes(item.idff_ticket_bill) ? item.state = "Paid": item)
			// Remove paid tickets in where filter state is not paid
			let tempt = temp.filter((item) => response?.data.includes(item.idff_ticket_bill) && state.filter.state.id === "Not paid" ? false : true)
			setState((prev) => ({ ...prev, tickets: tempt }));

			if (response?.errors.length > 0) {
				showToast(`${response?.errors.length} tickets not updated`, "warning")
			}else{
				setState((prev) => ({ ...prev, tickets: [] }));
				setState((prev) => ({ ...prev, tickets: tempt, selectedRows: [], paymentNumber: "" }));
				showToast("All payments updated successfully")
			};
		} else {
			console.log("[BillsAndCuttingTicketUpdate] update error", response);
			helpers.failRequest(response, showToast, signOut);
		}
		showLoader(false);
	}

	const destroy = async (id) => {
		if (window.confirm("Are you sure you want to delete this ticket?")) {
			showLoader(true);
			const response = await del(`/farmForest/billsAndCuttingTickets/${id}`);
			if (response.status === 200) {
				makeRequest();
				showToast(response.message);
			} else {
				console.log("[BillsAndCuttingTicketDestroy] destroy error", response);
				helpers.failRequest(response, showToast, signOut);
			}
			showLoader(false);
		}
	};

	const renderDate = (params) => dayjs(params.value).format("MM/DD/YYYY");

	const valueGetter = (params) => params.value.name;

	const renderCell = ({ row }) => {
		return (
			<div>
				<Tooltip title="Show detail" placement="right">
					<Link to={`/farmForest/billsAndCuttingTickets/${row.idff_ticket_bill}`} target="_blank">
						<IconButton>
							<Icon>{"visibility"}</Icon>
						</IconButton>
					</Link>
				</Tooltip>

				<Link to={`/history/ff_ticket_bill/${row.idff_ticket_bill}`} target="_blank">
					<Tooltip title="Show history" placement="top">
						<IconButton>
							<Icon>{"history"}</Icon>
						</IconButton>
					</Tooltip>
				</Link>

				{state.permissions?.delete_attr && (
				<Tooltip title="Delete" placement="top">
					<IconButton onClick={e => destroy(row.idff_ticket_bill)}>
						<Icon>{"delete"}</Icon>
					</IconButton>
				</Tooltip>
				)}
			</div>
		);
	};

	const columns = [
		{ field: "code", headerName: "No. ticket", flex: 0.5 },
		{ field: "date", headerName: "Date", flex: 1, valueGetter: renderDate },
		{ field: "supplier", headerName: "Supplier", flex: 1, valueGetter },
		{ field: "land", headerName: "Land", flex: 1, valueGetter },
		{ field: "state", headerName: "State", flex: 1 },
		{ field: "total", headerName: "Total", flex: 1 },
		{ field: "detail", headerName: "Detail", flex: 1, minWidth: 150, renderCell },
	];

	if (state.loading) {
		return <Loader />;
	}

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={6} lg={6}>
					<FormGroup>
						<FormLabel>{"No. Ticket"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput autoComplete="off" type="text" placeholder="No. ticket" name="code" onChange={handleChange} value={state.filter.code} />
						</FormControl>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={6}>
					<FormGroup onClick={handleModal}>
						<FormLabel>{"Date range"}</FormLabel>
						<FormControl variant="filled" component={Box} width="100%">
							<FilledInput readOnly autoComplete="off" type="text" placeholder="Date range" name="range" value={state.filter.dateRange} />
						</FormControl>
					</FormGroup>
				</Grid>
			</Grid>
			<Grid container>
			<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Supplier"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.suppliers}
							value={state.filter.supplier}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("supplier", value)}
							renderInput={(params) => <TextField {...params} placeholder="Supplier" />}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"Land"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.lands}
							value={state.filter.land}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("land", value)}
							renderInput={(params) => <TextField {...params} placeholder="Land" />}
						/>
					</FormGroup>
				</Grid>
				<Grid item xs={6} lg={4}>
					<FormGroup>
						<FormLabel>{"State"}</FormLabel>
						<Autocomplete
							disabled={Boolean(state.filter.code.trim())}
							options={state.states}
							value={state.filter.state}
							getOptionLabel={(option) => option?.name ?? ""}
							getOptionSelected={(option, value) => option.id === value.id}
							onChange={(event, value) => handleChangeAutocomplete("state", value)}
							renderInput={(params) => <TextField {...params} placeholder="State" />}
						/>
					</FormGroup>
				</Grid>
			</Grid>

			<div className={classes.buttonsContainerRight}>
				{state.permissions.delete_attr && <Trash table="ff_ticket_bill" id="idff_ticket_bill" onRefresh={makeRequest} />}
				<Button variant="contained" className={classes.search} onClick={search}>
					{"Search"}
				</Button>
			</div>

			<div hidden={!isPayment}>
				<Divider className={classes.dividerClass}/>
				<Grid container>
					<Grid item xs={6} lg={4}>
						<FormGroup>
							<FormLabel>{"Payment Number"}</FormLabel>
							<FormControl variant="filled" component={Box} width="100%">
								<FilledInput autoComplete="off" type="text" placeholder="Payment Number" name="payment_number" onChange={handlePayentNumber} value={state.paymentNumber} />
							</FormControl>
						</FormGroup>
					</Grid>
					<Grid item xs={6} lg={1} className={classes.buttonContainer} >
							<Button variant="contained" className={classes.payButton} onClick={updatePaymentNumber} disabled={state.selectedRows.length <= 0}>
								{"Pay"}
							</Button>
					</Grid>
				</Grid>
			</div>

			<div className={classes.tableContainer}>
				<DataGrid checkboxSelection={isPayment} onSelectionModelChange={handleSelectionRows} disableSelectionOnClick rows={state.tickets} columns={columns} pageSize={45} density="compact" autoHeight getRowId={(row) => row.idff_ticket_bill} />
			</div>

			<DateRange open={state.rangeModal.open} range={state.rangeModal.range} onClose={handleModal} onChange={handleDateChange} />
		</div>
	);
}
