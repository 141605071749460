import React, { useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";

import Parent from "../../common/parent";
import SelectPage from "../../common/select-page";
import NotFound from "../../common/not-found";
import Settings from "../../common/module-settings";

import CashierControl from "./control";
import CashierControlReport from "./control-report";

const useStyles = makeStyles((theme) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingTop: 0,
	},
}));

export default function CashierParent() {
	const classes = useStyles();
	const { path } = useRouteMatch();
	const [module, setModule] = useState(null);

	const update = (module) => {
		if (module) {
			setModule(module);
		}
	};

	return (
		<Parent module={module} updateModule={update}>
			<main className={classes.content}>
				<Toolbar />
				<Switch>
					<Route exact path={path} render={(props) => <SelectPage />} />
					<Route path={`${path}/control`} render={(props) => <CashierControl {...props} />} />
					<Route path={`${path}/controlReport`} render={(props) => <CashierControlReport {...props} />} />
					<Route path={`${path}/settings`} render={(props) => <Settings {...props} module={module} updateModule={update} />} />
					<Route path={`${path}/*`} component={NotFound} />
				</Switch>
			</main>
		</Parent>
	);
}
