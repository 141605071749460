import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import Parent from '../../common/parent';
import SelectPage from '../../common/select-page';
import NotFound from '../../common/not-found';
import Settings from '../../common/module-settings';

import Stations from './stations';
import Productions from './productions';
import Reports from './reports';
import EmployeeSchedule from './employee-schedule';
import EmployeePay from './employee-pay';
import ProductionStartTime from './production-start-time';
import ProductionTimes from './production-times';
import BreakTime from './break-time';
import EmployeeCategory from './employee-category';
import EmployeeSubcategory from './employee-subcategory';
import Subsede from './subsede';
import CostCenter from './cost-center';
import CostCenter2 from './cost-center-2';
import CostCenter3 from './cost-center-3';
import Productions2 from './production-2';
import EmployeeHour from './employee-hour';
import Employees from './employees';
import EmployeeHourAction from './employee-hour-action';
import EmployeePayBiweekly from './employee-pay-biweekly';
import EmployeePayDaily from './employee-pay-daily';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0
    }
}));

const ProcessParent = () => {

    const classes = useStyles();
    const { path } = useRouteMatch();
    const [module, setModule] = useState(null);

    const update = module => {
        if (module) {
            setModule(module);
        }
    }

    return (
        <Parent module={module} updateModule={update}>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route exact path={path} render={props => <SelectPage />} />
                    <Route path={`${path}/employeePayDaily`} render={props => <EmployeePayDaily {...props} />} />
                    <Route path={`${path}/employeePayBiweekly`} render={props => <EmployeePayBiweekly {...props} />} />
                    <Route path={`${path}/employeeHourAction`} render={props => <EmployeeHourAction {...props} />} />
                    <Route path={`${path}/employees`} render={props => <Employees {...props} />} />
                    <Route path={`${path}/employeeHour`} render={props => <EmployeeHour {...props} />} />
                    <Route path={`${path}/production2`} render={props => <Productions2 {...props} alone={false} />} />
                    <Route path={`${path}/costCenter3`} render={props => <CostCenter3 {...props} />} />
                    <Route path={`${path}/costCenter2`} render={props => <CostCenter2 {...props} />} />
                    <Route path={`${path}/costCenter`} render={props => <CostCenter {...props} />} />
                    <Route path={`${path}/subsede`} render={props => <Subsede {...props} />} />
                    <Route path={`${path}/employeeSubcategory`} render={props => <EmployeeSubcategory {...props} />} />
                    <Route path={`${path}/employeeCategory`} render={props => <EmployeeCategory {...props} />} />
                    <Route path={`${path}/breakTime`} render={props => <BreakTime {...props} />} />
                    <Route path={`${path}/productionTime`} render={props => <ProductionTimes {...props} />} />
                    <Route path={`${path}/productionStartTime`} render={props => <ProductionStartTime {...props} />} />
                    <Route path={`${path}/employeePay`} render={props => <EmployeePay {...props} />} />
                    <Route path={`${path}/employeeSchedule`} render={props => <EmployeeSchedule {...props} />} />
                    <Route path={`${path}/reportsProductions`} render={props => <Reports {...props} />} />
                    <Route path={`${path}/stations`} render={props => <Stations {...props} />} />
                    <Route path={`${path}/productions`} render={props => <Productions {...props} />} />
                    <Route path={`${path}/settings`} render={props => <Settings {...props} module={module} updateModule={update} />} />
                    <Route path={`${path}/*`} component={NotFound} />
                </Switch>
            </main>
        </Parent>
    );
}

export default ProcessParent;